import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

const BenefitItem = ({ image, icono, title, description, styles }) => (
  <div className={`partners-benefits__card ${styles}`}>
    <div className="partners-benefits__card-img">
      {icono || <Image src={`agencies/partnersBenefits/${image}`} alt="" />}
    </div>
    <div className="partners-benefits__card--content">
      <p className="partners-benefits__card--content-title">{title}</p>
      <p className="partners-benefits__card--content-description">
        {description}
      </p>
    </div>
  </div>
);

BenefitItem.propTypes = {
  image: PropTypes.string,
  icono: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  styles: PropTypes.string,
};

export default BenefitItem;
