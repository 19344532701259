/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';
import { Button, ButtonText } from '@andes/button';

import FAQItem from './components/FAQItem';
import Tracker from '../../../Utils/tracker';

const FAQ = ({ i18n, questions, lang, country, melidataPath }) => {
  const [showingIndex, setShowingIndex] = useState({
    id: '0',
    description: null,
  });

  useEffect(() => {
    if (showingIndex.description) {
      Tracker.trackAnalyticsEvent({
        label: `ACQUISITION_LANDING_FAQ_${showingIndex.id}`,
      });
      Tracker.melidataTrack(melidataPath, {
        event: `ACQUISITION_LANDING_FAQ_${showingIndex.id}`,
      });
    }
  }, [showingIndex.description]);

  return (
    <section className="faq" id="acquisition-landing-faq">
      <Image
        src="agencies/miscelanea.svg"
        alt="FAQ decoration"
        className="faq__img"
      />
      <div className="faq--title-container">
        <h2 className="faq--title">{i18n.gettext('¿Tienes dudas?')}</h2>
        <h2 className="faq--title--margen">
          {i18n.gettext('Consulta nuestras preguntas frecuentes')}
        </h2>
      </div>

      {questions.map((question, index) => (
        <FAQItem
          question={question}
          i18n={i18n}
          index={index}
          showingIndex={showingIndex}
          setShowingIndex={setShowingIndex}
          key={question.id}
          isFirst={index === 0}
        />
      ))}
      <div className="faq--action">
        <Button
          hierarchy="transparent"
          href={`https://www.mercadopago.com.${country}/developers/${lang}/support/28663`}
          target="_blank"
          onClick={() => {
            Tracker.trackAnalyticsEvent({
              label: 'ACQUISITION_LANDING_FAQS_ACTION_CLICK',
            });
            Tracker.melidataTrack(melidataPath, {
              event: 'ACQUISITION_LANDING_FAQS_ACTION_CLICK',
            });
          }}
        >
          <ButtonText>{i18n.gettext('Acceder a otras dudas')}</ButtonText>
        </Button>
      </div>
    </section>
  );
};

FAQ.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  lang: PropTypes.string,
  country: PropTypes.string,
  melidataPath: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};

export default FAQ;
