import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Nordic
import { Hotjar } from 'nordic/hotjar';
import config from 'nordic/config';
import { useI18n } from 'nordic/i18n';
// Libs
import { GTMNoScript, GTMScript } from '@landings-library/google-tag-manager';

// Components
import Home from '../../../../components/agencies/Home';
import HeaderHero from '../../../../components/agencies/HeaderHero';
import Footer from '../../../../components/agencies/Footer';
import PreFooter from '../../../../components/agencies/PreFooter';
import { getBenefits } from '../../../../components/agencies/Home/config/config-benefits';
import { getFAQQuestions } from '../../../../components/agencies/Home/config/config-faqs';
import { getClientBenefits } from '../../../../components/agencies/Home/config/config-clients-benefits';
import { getPayment } from '../../../../components/agencies/Home/config/config-payment';
// Services
import successCasesService from '../../../../../services/successCases';

const Agencies = (props) => {
  const {
    gtmId,
    hotjar,
    lang,
    countryId,
    deviceType,
    melidataPath,
    siteId,
    successCases,
    translate,
    isYellowTheme,
  } = props;
  const country = countryId?.toLowerCase();
  const { i18n } = useI18n();
  const [isMobile, setIsMobile] = useState(false);

  const benefits = getBenefits(i18n, siteId, country);
  const questions = getFAQQuestions(i18n, siteId);
  const clientBenefits = getClientBenefits(i18n, siteId, country, lang);
  const paymentSolution = getPayment(i18n);

  useEffect(() => setIsMobile(deviceType === 'mobile'), [deviceType]);

  return (
    <>
      <HeaderHero
        i18n={i18n}
        lang={lang}
        country={country}
        melidataPath={melidataPath}
        isMobile={isMobile}
        isYellowTheme={isYellowTheme}
      />
      <Home
        i18n={i18n}
        siteId={siteId}
        deviceType={deviceType}
        lang={lang}
        country={country}
        successCases={successCases}
        translate={translate}
        benefits={benefits}
        isMobile={isMobile}
        questions={questions}
        clientBenefits={clientBenefits}
        paymentSolution={paymentSolution}
        melidataPath={melidataPath}
        isYellowTheme={isYellowTheme}
      />
      <Footer
        i18n={i18n}
        background="white"
        lang={lang}
        analyticsLabel="SUBSCRIBE_AS_PARTNER"
        country={country}
        melidataPath={melidataPath}
        isYellowTheme={isYellowTheme}
      />
      <PreFooter i18n={i18n} background="white" />
      <GTMNoScript gtmId={gtmId} />
      <GTMScript gtmId={gtmId} />
      <Hotjar {...hotjar} />
    </>
  );
};

export const hydrate = 'hydrateRoot';

export const getServerSideProps = async (req, res) => {
  const { i18n, device, platform, params } = req;
  const { gtmId, hotjar, melidata, translate } = config;
  const { lang } = res.locals;
  const { countryId } = platform;
  const deviceType = device.type;
  const { siteId } = platform;
  const siteTranslate = translate[siteId === 'MLB' ? 'MLB' : 'DEFAULT'];
  const typeTitle = params?.lang === 'es' ? 'Agencias' : 'Agências';

  const successCases = await successCasesService.get(req, res);

  const isYellowTheme = req?.mercadopago?.brand === 'yellowblue-light';

  const melidataWithEventData = {
    path: melidata.path,
    event_data: { event: 'AGENCIES_HOME' },
  };

  // Validation feature flag rebranding
  const suffix =
    req?.mercadopago?.brand === 'yellowblue-light'
      ? 'yellow-theme/'
      : 'blue-theme/';

  return {
    props: {
      gtmId,
      hotjar,
      lang,
      countryId,
      deviceType,
      siteId,
      melidataPath: melidata.pathAction,
      translate: siteTranslate,
      successCases,
      userData: {
        name: res.locals.name,
        avatar: res.locals.avatar,
        isDevCetificate: res.locals.isDevCertificate,
        id: req.auth?.user?.id,
        email: res.locals.email,
      },
      actualUrl: {
        host: `https://${req.get('host')}`,
        path: req.baseUrl + req.path,
        fullPath: req.originalUrl,
        localPath: req.path,
      },
      isYellowTheme,
    },
    settings: {
      title: `${i18n?.gettext('Programa de Partners')} | ${typeTitle}`,
      melidataWithEventData,
      imagesPrefix: config.assets.prefix + suffix,
    },
  };
};

export const setPageSettings = ({ settings }) => ({
  id: 'home',
  className: 'home partners',
  title: settings.title,
  imagesPrefix: settings?.imagesPrefix,
  melidata: settings.melidataWithEventData,
  navigation: {
    type: 'full',
  },
  layout: {
    staticMarkup: false,
  },
});

Agencies.propTypes = {
  gtmId: PropTypes.string,
  hotjar: PropTypes.shape({
    id: PropTypes.number,
    version: PropTypes.string,
  }),
  lang: PropTypes.string,
  countryId: PropTypes.string,
  deviceType: PropTypes.string,
  melidataPath: PropTypes.string,
  siteId: PropTypes.string,
  successCases: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  translate: PropTypes.shape({}),
  isYellowTheme: PropTypes.bool,
};

export default Agencies;
