import React from 'react';
import PropTypes from 'prop-types';

import ClientBenefits from './ClientBenefits';
import SuccessCases from './SuccessCases';
import PartnersBenefits from './PartnersBenefits';
import Levels from './Levels';
import Events from './Events';
import Steps from './Steps';
import FAQ from './FAQ';
import PaymentSolution from './PaymentSolution';

const Home = ({
  i18n,
  lang,
  successCases,
  benefits,
  questions,
  clientBenefits,
  paymentSolution,
  isMobile,
  country,
  melidataPath,
  isYellowTheme,
}) => {
  const eventsAvailableSites = ['br', 'mx'];

  return (
    <section className="s-info">
      <PaymentSolution i18n={i18n} paymentSolution={paymentSolution} />
      <PartnersBenefits i18n={i18n} benefits={benefits} />
      <ClientBenefits
        i18n={i18n}
        clientBenefits={clientBenefits}
        isMobile={isMobile}
      />
      <Steps
        i18n={i18n}
        lang={lang}
        country={country}
        melidataPath={melidataPath}
        analyticsLabel="ACQUISITION_LANDING_STEPS_ACTION_CLICK"
      />
      <Levels i18n={i18n} lang={lang} melidataPath={melidataPath} />
      {successCases?.length > 0 && !(country === 'mx' && isYellowTheme) && (
        <SuccessCases
          i18n={i18n}
          isMobile={isMobile}
          successCases={successCases}
          isYellowTheme={isYellowTheme}
        />
      )}
      {eventsAvailableSites.includes(country) && (
        <Events i18n={i18n} isMobile={isMobile} country={country} />
      )}
      <FAQ
        i18n={i18n}
        questions={questions}
        lang={lang}
        country={country}
        melidataPath={melidataPath}
      />
    </section>
  );
};

Home.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  successCases: PropTypes.arrayOf(
    PropTypes.shape({
      partner: PropTypes.string,
      description: PropTypes.string,
      author: PropTypes.string,
      img: PropTypes.string,
      integrator_id: PropTypes.string,
      site: PropTypes.string,
    }),
  ),
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  clientBenefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  lang: PropTypes.string,
  country: PropTypes.string,
  melidataPath: PropTypes.string,
  paymentSolution: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.string,
  }),
  isMobile: PropTypes.bool,
  isYellowTheme: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      question: PropTypes.string,
      answer: PropTypes.string,
    }),
  ),
};

export default Home;
