import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

import HeaderContent from './components/HeaderContent';
import HeaderImage from './components/HeaderImage';

const HeaderHero = ({
  i18n,
  lang,
  isMobile,
  country,
  melidataPath,
  isYellowTheme,
}) => (
  <section className="header-hero">
    <Image
      className="background-rebranding"
      src={isMobile ? 'agencies/bannermobile.svg' : 'agencies/banner.svg'}
      alt=""
    />
    <div className="header">
      <HeaderContent
        i18n={i18n}
        lang={lang}
        country={country}
        melidataPath={melidataPath}
        isYellowTheme={isYellowTheme}
      />
      <HeaderImage />
    </div>
  </section>
);

HeaderHero.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  lang: PropTypes.string,
  country: PropTypes.string,
  melidataPath: PropTypes.string,
  isMobile: PropTypes.bool,
  isYellowTheme: PropTypes.bool.isRequired,
};

export default HeaderHero;
