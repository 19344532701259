/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

import PaymentList from './components/PaymentList';

const PaymentSolution = ({ i18n, paymentSolution }) => {
  return (
    <section className="payment-solution" id="payment-solution">
      <div className="payment-solution--container">
        <Image src="agencies/miscelanea.svg" alt="" />
        <h2 className="payment-solution--container-title">
          {i18n.gettext(
            'Un programa para agencias que ofrecen Mercado Pago como solución de pago para sus clientes',
          )}
        </h2>
        <PaymentList paymentSolution={paymentSolution} />
      </div>
    </section>
  );
};

PaymentSolution.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  paymentSolution: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};

export default PaymentSolution;
